var treatments = document.getElementsByClassName('treatments');
if(treatments.length) {
    Sortable.create(treatments[0]);
}

var subtreatments = document.getElementsByClassName('subtreatments');
if(subtreatments.length) {
    Sortable.create(subtreatments[0]);
}

$("a[data-confirm]").click(function(e) {
    var title = $(this).data('confirm-title');
    var body = $(this).data('confirm-body');
    var destination = $(this).attr('href');
    if(!$(this).data('pass')) {
        e.preventDefault();
        $('.modal').modal('show');
        callModal(title, body, function(button) {
            if(button) {
                window.location = destination;
            }
        });
    }
});

function callModal(title, body, callback) {
    $('.modal .modal-title').html(title);
    $('.modal .modal-body > p').html(body);
    $('.modal').modal('show').find('button').click(function () {
        callback(parseInt($(this).data('value')));
    });
}